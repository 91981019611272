import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "detail-with-tabs repository-detail" }
const _hoisted_2 = {
  key: 0,
  class: "position-top"
}
const _hoisted_3 = { class: "service-depened-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_service_name = _resolveComponent("service-name")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_service_base = _resolveComponent("service-base")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_service_depend = _resolveComponent("service-depend")!
  const _component_service_api = _resolveComponent("service-api")!
  const _component_service_history = _resolveComponent("service-history")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showSelect)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_select, {
            modelValue: _ctx.currentServiceId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentServiceId) = $event)),
            placeholder: "请选择",
            onChange: _ctx.selectService,
            style: {"width":"200px"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serverList, (server) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: server.id,
                  value: server.id,
                  label: server.shortName
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_service_name, {
                      name: server.name
                    }, null, 8, ["name"])
                  ]),
                  _: 2
                }, 1032, ["value", "label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ]))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createBlock(_component_el_tabs, {
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTab) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "基本信息",
          name: "base"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_service_base, {
              loading: _ctx.loading,
              info: _ctx.serviceInfo
            }, null, 8, ["loading", "info"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "服务依赖",
          name: "depend"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_service_depend, { ref: "serviceDependRef" }, null, 512)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "接口列表",
          name: "api"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_service_api, {
              loading: _ctx.loading,
              info: _ctx.serviceInfo,
              detailName: _ctx.$route.query.detailName,
              "is-pro": _ctx.isPro
            }, null, 8, ["loading", "info", "detailName", "is-pro"])
          ]),
          _: 1
        }),
        (!_ctx.isPro)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: "历史版本",
              name: "history"
            }, {
              default: _withCtx(() => [
                (_ctx.serviceInfo)
                  ? (_openBlock(), _createBlock(_component_service_history, {
                      key: 0,
                      info: _ctx.serviceInfo,
                      isService: _ctx.isService
                    }, null, 8, ["info", "isService"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}