
import { defineComponent, getCurrentInstance, reactive, ref, watch } from 'vue';
import { ENV_ENUM } from '@/views/env/type';
import { useRoute } from 'vue-router';
import { getRepositoryDetail } from '@/api/repository';
import ServiceDepend from '../components/depend/Index.vue';
import ServiceBase from './Base.vue';
import ServiceApi from './Api.vue';
import ServiceHistory from './History.vue';
import { getServiceApiList, getServiceInfoById, getServiceList } from '@/api/servers';
import { parseServiceInfo, parseRepositoryInfo } from '@/views/service-repository/detail/util';
import { getServiceShowName } from '@/views/service-management/components/utils';
import ServiceName from '@/views/service-management/components/ServiceName.vue';

export default defineComponent({
  name: 'RepositoryPlatformDetail',
  components: {
    ServiceDepend,
    ServiceBase,
    ServiceApi,
    ServiceHistory,
    ServiceName,
  },
  props: {
    isPro: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const currentServiceId = ref(Number(route.params.id));
    const currentType = ref(Number(route.query.type));
    console.log('currentType', currentType.value);
    const serverList = reactive([] as any[]);
    const getServerList = async () => {
      const { data } = await getServiceList({
        all: true,
        env: props.isPro ? ENV_ENUM.PROD : ENV_ENUM.TEST,
      });
      data.rows.forEach((x: any) => {
        // eslint-disable-next-line no-param-reassign
        x.shortName = x.name ? getServiceShowName(x.name) : '';
      });
      serverList.length = 0;
      serverList.push(...(data.rows || []));
      currentServiceId.value = Number(route.params.id);
    };

    let serviceFullName = route.query.detailName;
    if (serviceFullName) {
      sessionStorage.setItem('serviceDetail_serviceFullName', serviceFullName.toString());
    } else {
      serviceFullName = sessionStorage.getItem('serviceDetail_serviceFullName');
    }

    getServerList();

    const showSelect = ref(route.fullPath.includes('service-list') || route.fullPath.includes('business-pro'));

    const activeTab = ref('base');
    const serviceDependRef = ref(null as any);
    const serviceInfo = ref(null as any);
    const isService = ref(route.name === 'ServiceDetail' || route.name === 'ServiceListProDetail');
    const loading = ref(true);
    const fetchData = async () => {
      loading.value = true;
      // 判断服务类型
      let data = null;
      if (isService.value) {
        const [infoRes, apiRes] = await Promise.all([
          getServiceInfoById({
            id: currentServiceId.value,
          }),
          getServiceApiList({
            serviceId: Number(currentServiceId.value),
          }),
        ]);
        data = parseServiceInfo(infoRes.data, apiRes.data);
      } else {
        const res = await getRepositoryDetail(currentServiceId.value as any);
        data = parseRepositoryInfo(res.data);
      }
      serviceInfo.value = data;
      loading.value = false;
    };
    fetchData();

    const { proxy } = getCurrentInstance() as any;
    const activedDependTab = ref(false);
    watch(activeTab, (tab) => {
      if (tab === 'depend' && !activedDependTab.value) {
        serviceDependRef.value.render(serviceInfo.value.name, serviceInfo.value.serviceVersion, 'service');
        activedDependTab.value = true;
      }
    });
    const selectService = (res: any) => {
      proxy.$router.replace({
        path: `/services/business/detail/${res}`,
      });
      activeTab.value = 'base';
      activedDependTab.value = false;
      fetchData();
      proxy.$forceUpdate();
    };
    return {
      activeTab,
      serviceDependRef,
      serviceInfo,
      loading,
      isService,
      currentServiceId,
      serverList,
      selectService,
      showSelect,
    };
  },
});
