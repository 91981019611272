
import { defineComponent, watch, ref } from 'vue';
const METHOD_TYPES: string[] = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'];
export default defineComponent({
  name: 'ServiceApi',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    detailName: {
      type: String,
    },
    isPro: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const apiList = ref([] as any);
    const models = ref([] as any);
    const getMethodName = (method: number) => METHOD_TYPES[method] || '';

    const parseApiList = (info: any) => {
      try {
        apiList.value = info.apiList;
        models.value = info.models;
      } catch (e) {
        apiList.value = [];
      }
    };
    const getModelName = (modelId: number) => {
      const model = models.value.find((item: any) => item.id === Number(modelId));
      return model?.name || '';
    };
    if (props.info) {
      parseApiList(props.info);
    }

    watch(
      () => props.info,
      (newValue) => {
        parseApiList(newValue);
      },
    );
    return {
      apiList,
      models,
      getModelName,
      getMethodName,
    };
  },
});
